import Homepage from './components/pages/Homepage'
import Header from './components/header/Header.comp'
import Footer from './components/footer/Footer.comp'

function App() {
  return (
    <div className="content">
      <Header />
      <Homepage />
      <Footer />
    </div>
  )
}

export default App
