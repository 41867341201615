import React, { Component } from 'react'
import Gameslist from './Gameslist'

export default class Homepage extends Component {
  render() {
    return (
      <>
        <Gameslist />
      </>
    )
  }
}
