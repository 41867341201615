import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import Games from '../../data/GamesListData.json'

export default class Gameslist extends Component {
  render() {
    return (
      <>
        <div className="body">
          {Games &&
            Games.map((game) => {
              return (
                <div className="gameCard" key={game.id}>
                  <img
                    src={game.image}
                    alt={game.title}
                    area-label={game.title}
                  />
                  <ul>
                    <li>
                      <p className="gameTitle">{game.title}</p>
                    </li>
                    <li>
                      {game.link ? (
                        <div className="d-grid gap-2">
                          <Button
                            href={game.link}
                            variant="light"
                            target="_blank"
                          >
                            Trailer
                          </Button>
                        </div>
                      ) : (
                        ''
                      )}
                    </li>
                  </ul>
                </div>
              )
            })}
        </div>
      </>
    )
  }
}
